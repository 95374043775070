import { bindable, containerless } from 'aurelia-framework';

@containerless()
export class TabsNavElement {

    @bindable active   = false;
    @bindable target   = null;
    @bindable icon     = null;
    @bindable label    = null;
    @bindable disabled = false;
    @bindable minified = true;

}
