import { bindable, bindingMode, noView } from 'aurelia-framework';

@noView()
export class ScriptInjector {

    @bindable url;
    @bindable isAsync    = true;
    @bindable isDeferred = true;
    @bindable({defaultBindingMode: bindingMode.oneWay}) scripttag;

    /**
     * Handles attached event
     */
    attached() {
        console.log(this.url);
        if (this.url) {
            this.scripttag = document.createElement('script');

            this.scripttag.async = this.isAsync;
            this.scripttag.defer = this.isDeferred;

            this.scripttag.setAttribute('src', this.url);

            document.body.appendChild(this.scripttag);
        }
    }

    /**
     * Handles detached event
     */
    detached() {
        if (this.scripttag) {
            this.scripttag.remove();
        }
    }

}
